import { render, staticRenderFns } from "./error.vue?vue&type=template&id=1fbb7e76&scoped=true&slot=md-tab&slot-scope=%7B%20tab%20%7D&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=1fbb7e76&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fbb7e76",
  null
  
)

export default component.exports