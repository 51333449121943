import store from '../state/store';

export default [
    {
        path: '/login',
        name: 'login',
        component: () => lazyLoadView(import('@carrier/views/auth/Login')),
        meta: {
            app: 'default',
            layout: 'auth',
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'home' });
                } else {
                    next();
                }
            }
        }
    },

    {
        path: '/logout',
        name: 'logout',
        meta: {
            app: 'default',
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logout');
                OneSignal.setSubscription(false);
                next({ path: '/' });
            }
        }
    },

    {
        path: '/reset-password',
        name: 'reset-password',
        component: () =>
            lazyLoadView(import('@carrier/views/auth/ResetPassword')),
        props: route => ({ token: route.params.token }),
        meta: {
            app: 'default',
            layout: 'auth',
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'home' });
                } else {
                    next();
                }
            }
        }
    },

    {
        path: '/reset-password/:code/',
        name: 'recover-password',
        component: () =>
            lazyLoadView(import('@carrier/views/auth/RecoverPassword')),
        props: route => ({ token: route.params.token }),
        meta: {
            app: 'default',
            layout: 'auth',
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'home' });
                } else {
                    next();
                }
            }
        }
    },

    {
        path: '/app/:inquiryUUID',
        name: 'route',
        component: () => lazyLoadView(import('@carrier/views/Route')),
        meta: {
            app: 'default',
            layout: 'default',
            authRequired: true,
            app: true
        }
    },

    {
        path: '/',
        name: 'home',
        component: () => lazyLoadView(import('@carrier/views/Home')),
        meta: {
            app: 'default',
            layout: 'default',
            authRequired: true
        }
    },

    {
        path: '/taken-passengers',
        name: 'taken-passengers',
        component: () => lazyLoadView(import('@carrier/views/TakenPassengers')),
        meta: {
            app: 'default',
            layout: 'default',
            authRequired: true
        }
    },

    {
        path: '/settings',
        name: 'settings',
        component: () => lazyLoadView(import('@carrier/views/Settings')),
        meta: {
            app: 'default',
            layout: 'default',
            authRequired: true
        }
    },

    {
        path: '/deposits',
        name: 'deposits',
        component: () => lazyLoadView(import('@carrier/views/Deposits')),
        meta: {
            app: 'default',
            layout: 'default',
            authRequired: true
        }
    },

    {
        path: '/transfer',
        name: 'transfer',
        component: () => lazyLoadView(import('@carrier/views/Transfer')),
        meta: {
            app: 'default',
            layout: 'default',
            authRequired: true
        }
    },

    {
        path: '/*',
        name: 'error',
        component: () => lazyLoadView(import('@carrier/views/errors/NotFound')),
        meta: {
            app: 'default',
            layout: 'error',
            authRequired: false
        }
    }
];

function lazyLoadView(AsyncView) {
    const AsyncHandler = () => ({
        component: AsyncView,
        delay: 400,
        timeout: 10000
    });

    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            return h(AsyncHandler, data, children);
        }
    });
}
