import { timeAgo, formatDate, isExpired } from '@carrier/helpers/time';
import { shortText } from '@carrier/helpers/shortText';

export default {
    install(Vue, options) {
        Vue.prototype.$timeAgo = timeAgo;
        Vue.prototype.$formatDate = formatDate;
        Vue.prototype.$isExpired = isExpired;
        Vue.prototype.$shortText = shortText;
    }
};
