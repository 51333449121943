<template slot="md-tab" slot-scope="{ tab }">
    <div>
        <div id="md-toolbar">
            <md-toolbar class="md-small md-primary">
                <div class="md-toolbar-row container">
                    <div class="md-toolbar-section-end">
                        <img
                            src="@assets/images/logo.svg"
                            alt="logo"
                            title="logo"
                            width="112"
                            height="23"
                        />
                    </div>
                </div>
            </md-toolbar>
        </div>

        <slot />
    </div>
</template>

<script>
export default {
    data() {
        return {};
    }
};
</script>
<style scoped>
.md-toolbar-section-end {
    justify-content: center;
}
</style>
