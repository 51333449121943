<template slot="md-tab" slot-scope="{ tab }">
    <div class="">
        <Header name="headerCompontent" />
        <div v-if="isOnline" class="content">
            <div class="container bgWhite">
                <slot />
            </div>
        </div>
        <div v-else class="offline-box">
            <div class="center">
                <img :src="require('@assets/images/icon_offline.svg')" />
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../components/layout/Header';

export default {
    components: {
        Header
    },

    data() {
        return {};
    },

    methods: {},

    computed: {},

    created() {}
};
</script>
