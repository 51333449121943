import axios from 'axios';

export const state = {
    lastCarrierRequests: [],
    takenPassengers: [],
    takenPassengersMeta: {},
    passengersStatuses: {}
};

export const getters = {
    carrierRequests: state => state.lastCarrierRequests,
    takenPassengersMeta: state => state.takenPassengersMeta,
    passengersStatuses: state => state.passengersStatuses
};

export const mutations = {
    ADD_PASSENGERS_STATUSES(state, response) {
        state.passengersStatuses = response;
    },

    CLEAR(state) {
        state.takenPassengersMeta = {};
    },

    CLEAR_LAST_REQUESTS(state) {
        state.lastCarrierRequests = [];
    },

    ADD_LAST_REQUESTS(state, response) {
        const { data, currentUser } = response;

        const filtered = data.filter(el => {
            return el.taken_by !== currentUser && el != null;
        });

        state.lastCarrierRequests = filtered;
    },

    ADD_TAKEN_PASSENGERS(state, response) {
        state.takenPassengersMeta = response._meta;
    },

    ADD_REQUEST(state, data) {
        const coppy = state.lastCarrierRequests;
        const { request_uuid, content } = data;
        coppy.unshift(content);

        const uniqueArray = coppy.filter(({ uuid, partner }, index) => {
            return (
                index ===
                coppy.findIndex(obj => {
                    return obj.uuid === uuid || partner === request_uuid;
                })
            );
        });

        state.lastCarrierRequests = uniqueArray;
    }
};

export const actions = {
    async getPassengersStatuses({ commit }, uuids) {
        const bodyFormData = new FormData();
        bodyFormData.append('uuids', uuids);
        try {
            const { data } = await axios.post(
                `get-passengers-statuses`,
                bodyFormData
            );

            commit('ADD_PASSENGERS_STATUSES', data);
            
            return data;
        } catch (error) {
            console.error(error);
        }
    },

    async lastCarrierRequests({ commit, dispatch }, currentUser) {
        try {
            const { data } = await axios.post(`last-carrier-requests/24`);

            if (data) {
                const uuids = data.map(item => {
                    return item.uuid;
                });

                dispatch('getPassengersStatuses', JSON.stringify(uuids));
            }

            commit('ADD_LAST_REQUESTS', { data, currentUser });

            return data;
        } catch (error) {
            console.error(error);
        }
    },

    async takenPassengers({ commit }, params) {
        if (params.page === 1) {
            commit('CLEAR');
        }

        const { data } = await axios.get(`taken-passengers`, params);

        commit('ADD_TAKEN_PASSENGERS', data);

        return data;
    }
};
