import Vue from 'vue';
import VueRouter from 'vue-router';
import VueGtag from 'vue-gtag';
import store from './../state/store';
import routes from './routes';
import config from '@config';
import {loadLanguageAsync} from '@src/plugins/i18n';

Vue.use(VueRouter);

const router = new VueRouter({
    routes,
    base: config.publicPath,
    mode: 'history',
    linkActiveClass: 'active'
});

Vue.use(
    VueGtag,
    {
        config: {id: config.googleAnalyticsDefaultId},
        includes: [{id: config.googleAnalyticsId}],
        appName: 'BUS-DO-POLSKI PWA',
        pageTrackerScreenviewEnabled: true,
        pageTrackerTemplate(to) {
            return {
                page_title: to.path,
                page_path: to.path
            };
        }
    },
    router
);

setInterval(() => {
    if (store.getters['auth/loggedIn']) {
        store.dispatch('auth/refreshToken');
        store.dispatch('auth/validate');

        const currentUser = store.getters['auth/currentUser'];

        OneSignal.push(() => {
            OneSignal.setExternalUserId(currentUser.uuid, currentUser.authHash);
        });
    }
}, 600000);

const loginByToken = (inquiryUUID, login_token) => {
    return store.dispatch('auth/loginByTokenAsCarrier', {
        token: login_token,
        uuid: inquiryUUID
    })
}

router.beforeEach((routeTo, routeFrom, next) => {
    const lang = routeTo.params.lng ? routeTo.params.lng : navigator.language;

    const authRequired = routeTo.matched.some(route => route.meta.authRequired);

    defaultApp(authRequired);

    function defaultApp(authRequired) {
        if (routeFrom.name === 'login' && store.getters['auth/loggedIn']) {
            const currentUser = store.getters['auth/currentUser'];

            OneSignal.push(() => {
                OneSignal.setExternalUserId(
                    currentUser.uuid,
                    currentUser.authHash
                );
            });

            return next();
        }

        if (authRequired && routeTo.name === 'logout') {
            return next();
        }

        if (routeTo.name === 'login') {
            loadLanguageAsync(lang).then(() => next());
        }

        if (!authRequired) return next();

        if (store.getters['auth/loggedIn']) {
            return loadLanguageAsync(lang).then(() => next());
        } else {
            const {login_token} = routeTo.query;

            if (login_token) {
                const response = loginByToken(routeTo.params.inquiryUUID, login_token);
                response.then((value) => {
                    if (value) {
                        return next(routeTo.path ? routeTo.path : '/');
                    } else {
                        return next({
                            name: 'login',
                            query: {redirectFrom: routeTo.path}
                        });
                    }
                });

            } else {
                return next({
                    name: 'login',
                    query: {redirectFrom: routeTo.path}
                });
            }
        }
    }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).

    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // Complete the redirect.
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    // Otherwise, continue resolving the route.
                    resolve();
                }
            });
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return;
    }

    // If we reach this point, continue resolving the route.
    next();
});

export default router;
