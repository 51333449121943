<template>
    <div id="app">
        <auth-layout v-if="$route.meta.layout === 'auth'">
            <router-view :key="$route.fullPath" />
        </auth-layout>
        <layout v-if="$route.meta.layout === 'default'">
            <router-view :key="$route.fullPath" />
        </layout>
        <layout-passenger v-if="$route.meta.layout === 'passenger'">
            <router-view :key="$route.fullPath" />
        </layout-passenger>
        <error-layout v-if="$route.meta.layout === 'error'">
            <router-view :key="$route.fullPath" />
        </error-layout>
    </div>
</template>

<script>
import Layout from './layouts/main';
import AuthLayout from './layouts/auth';
import ErrorLayout from './layouts/error';
import appConfig from '../../app.config.json';
import { mapActions } from 'vuex';

export default {
    page: {
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title;
            return title ? `${title} | ${appConfig.title}` : appConfig.title;
        },

        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        Layout,
        AuthLayout,
        ErrorLayout
    },

    data() {
        return {
            authRoutes: ['login', 'reset-password', 'forget-password'],
            landscape: true
        };
    },

    watch: {
        '$route.fullPath'() {
            let route = this.$route.fullPath.split('/')[1];
            route = route.split('?')[0];

            this.layout = this.$route.meta.layout;
        }
    },

    methods: {
        handleOrientationChange() {
            const orientation = window.screen.orientation.type;

            if (orientation === 'landscape-primary') {
                this.landscape = false;
            } else {
                this.landscape = true;
            }
        }
    },

    mounted() {        
        window.addEventListener(
            'orientationchange',
            this.handleOrientationChange
        );
    }
};
</script>
