<template>
    <div id="md-toolbar">
        <md-toolbar class="md-small md-primary">
            <div class="md-toolbar-row container">
                <div class="md-toolbar-section-start">
                    <img
                        src="@assets/images/logo.svg"
                        alt="logo"
                        title="logo"
                        width="112"
                        height="23"
                    />
                </div>

                <div class="md-toolbar-section-end">
                    <div id="points-full-text">
                        <div class="right points-desktop">
                            {{ loggedIn ? currentUser.credit : 0 }}
                        </div>
                        <small style="font-size:14px">{{
                            $t('header.points')
                        }}</small>
                    </div>

                    <md-button
                        class="default-button btn-add-points"
                        @click="creditModal"
                    >
                        <img
                            :src="require('@assets/images/icon_add.svg')"
                            alt="kredyt"
                            title="kredyt"
                        />
                        <div id="add-text" class="uppercase">{{ $t('header.addPoints') }}</div>
                    </md-button>

                    <md-menu md-size="medium" md-align-trigger>
                        <md-button
                            class="default-button btn-menu"
                            md-menu-trigger
                        >
                            <img
                                :src="require('@assets/images/icon_menu.svg')"
                                alt="menu"
                                title="menu"
                            />
                        </md-button>
                        <md-menu-content class="menu-content">
                            <md-menu-item
                                v-if="!isMobile"
                                href="https://bus-do-polski.pl/account/?login=true#map=5/49.425/12.986"
                                target="_blank"
                                >{{ $t('header.carrierPanel') }}</md-menu-item
                            >
                            <md-menu-item to="/transfer">{{
                                $t('header.passengerTransfer')
                            }}</md-menu-item>
                            <md-menu-item to="/settings">{{
                                $t('header.settings')
                            }}</md-menu-item>
                            <md-menu-item @click="logoutUser">{{
                                $t('header.logout')
                            }}</md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </div>
            </div>
        </md-toolbar>
        <md-tabs
            v-if="loggedIn"
            class="md-primary"
            md-alignment="centered"
            :md-active-tab="currenttab"
            router-link
        >
            <md-tab
                id="tab-home"
                :md-label="$t('header.requests')"
                to="/"
                exact
            />
            <md-tab
                id="tab-taken-passengers"
                :md-label="$t('header.taken')"
                to="/taken-passengers"
            />
            <md-tab
                id="tab-deposits"
                :md-label="$t('header.deposits')"
                to="/deposits"
            />
        </md-tabs>
        <buy-points-modal :show="showModal" @closeModal="showModal = false" />
    </div>
</template>

<script>
import BuyPointsModal from '../modals/buyPointsModal';
import { mapGetters, mapMutations } from 'vuex';

export default {
    components: {
        BuyPointsModal
    },

    data() {
        return {
            showModal: false,
            currenttab: 'tab-home',
            tabsNames: ['home', 'taken-passengers', 'deposits']
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            loggedIn: 'auth/loggedIn'
        }),

        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                return true;
            } else {
                return false;
            }
        }
    },

    watch: {
        '$route.name'() {
            if (
                this.tabsNames.includes(this.$route.name) ||
                this.$route.meta.app
            ) {
                this.$el
                    .getElementsByClassName('md-tabs-indicator')
                    .item(0).style.display = 'block';

                if (!this.$route.meta.app) {
                    this.currenttab = 'tab-' + this.$route.name;
                }
            } else {
                this.$el
                    .getElementsByClassName('md-tabs-indicator')
                    .item(0).style.display = 'none';

                this.$el.getElementsByClassName('md-active').item(0).style =
                    'color:rgba(255, 255, 255, 0.7)';
            }
        }
    },

    methods: {
        ...mapMutations({
            LOGOUT: 'auth/LOGOUT'
        }),

        creditModal() {
            this.showModal = !this.showModal;
        },

        logoutUser() {
            this.LOGOUT();

            location.href = '/';
        }
    }
};
</script>
