import axios from 'axios';

export const state = {
    websiteOpen: false
};

export const getters = {
    websiteOpen: state => state.websiteOpen,
    noticonf: state => state.noticonf
};

export const mutations = {
    WEBSITE_OPEN(state, data) {
        state.websiteOpen = data;
    }
};

export const actions = {
    async getNoticonf({ commit }) {
        const { data } = await axios.post(`noticonf`);

        return data;
    },

    async changeNoticonf({ commit }, params) {
        const { data } = await axios.post(`noticonf`, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return data;
    },

    async widgetCommission({ commit }, commission) {
        const params = new URLSearchParams();
        params.append('commission', commission);

        const { data } = await axios.post(`widget-commission`, params);

        return data;
    },

    async accountUpdate({ commit }, params) {
        const { password, currentUser } = params;

        const formData = new FormData();
        formData.append('password', password);
        formData.append('company_name', currentUser.company_name);
        formData.append('out_trips', currentUser.departs);
        formData.append('back_trips', currentUser.returns);
        formData.append('additional_options', currentUser.options);

        const { data } = await axios.post(`account/edit`, formData);

        return data;
    }
};
