import axios from 'axios';
const sha256 = require('js-sha256').sha256;

export const state = {
    currentUser: getSavedState('user'),
    refreshToken: getSavedState('refreshToken'),
    token: getSavedState('token'),
    openingCounter: getSavedState('openingCounter'),
    connectionError: false
};

export const getters = {
    loggedIn(state) {
        return !!state.currentUser;
    },

    currentUser: state => state.currentUser,

    connectionError: state => state.connectionError,

    openingCounter: state => state.openingCounter
};

export const mutations = {
    COUNTER_ADD(state, data) {
        if (state.openingCounter < 5) {
            let counter = getSavedState('openingCounter');
            counter++;

            saveState('openingCounter', counter);
        }
    },

    SET_TOKENS(state, data) {
        saveState('refreshToken', data.refresh_token);
        saveState('token', data.access_token);
    },

    SET_USER(state, data) {
        const user = data[0];
        const generateAuthHash = sha256.hmac(
            process.env.VUE_APP_ONESIGNAL_API_KEY,
            user.uuid
        );

        user.carrierUUID = data[0].uuid;
        user.phone = data[0].phonenumber;
        user.authHash = generateAuthHash;

        saveState('user', user);
        state.currentUser = user;
    },

    LOGOUT(state) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('openingCounter');
    },

    CONNECTION_ERROR(state) {
        this.state.connectionError = true;
    }
};

export const actions = {
    init({ state, dispatch }) {
        dispatch('validate');
    },

    logout({ commit }) {
        commit('LOGOUT', null);
    },

    async loginByTokenAsCarrier({ commit, dispatch, getters }, credential) {
        const { token, uuid } = credential;

        try {
            const { data } = await axios.get(`hash_login/${uuid}/${token}`);

            if (data !== 'Request not found') {
                commit('SET_TOKENS', data);
                return dispatch('validate');
            } else {
                return false;
            }

        } catch (error) {
            throw error;
        }
    },

    async login({ commit, dispatch, getters }, params) {
        if (getters.loggedIn) {
            return dispatch('validate');
        }

        const body = {
            grant_type: 'password',
            scope: 'user',
            username: params.email,
            password: params.password,
            client_id: 'testclient',
            client_secret: 'testpass'
        };
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'pl-PL'
        };

        try {
            const { data } = await axios.post('oauth2/token', body, {
                headers
            });

            commit('SET_TOKENS', data);

            return dispatch('validate');
        } catch (error) {
            throw error;
        }
    },

    async refreshToken({ commit }) {
        const refresh_token = JSON.parse(localStorage.refreshToken);

        if (!refresh_token) {
            commit('LOGOUT');
            return false;
        }

        const body = {
            grant_type: 'refresh_token',
            refresh_token,
            client_id: 'testclient',
            client_secret: 'testpass',
            scope: 'user'
        };

        try {
            const { data } = await axios.post('oauth2/token', body);

            commit('SET_TOKENS', data);
        } catch (error) {
            if (error.response && error.response.status <= 400) {
                commit('LOGOUT');
            }
        }
    },

    async validate({ commit, dispatch }) {
        if (localStorage.token) {
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + JSON.parse(localStorage.token);
        }

        try {
            const { data } = await axios.get('me');

            if (data) {
                commit('SET_USER', data);
            }

            return data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                commit('LOGOUT');
            }
        }
    },

    async resetPassword({ commit, state }, formData) {
        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        await axios.post(`new-password`, formData, { headers });
    },

    async changePassword({ commit, state }, formData) {
        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        const { data } = await axios.post(`change-password`, formData, {
            headers
        });

        return data;
    }
};

function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
}
