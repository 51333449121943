import axios from 'axios';
import config from '@config';
const flotea = config.flotea;
const geoFlotea = config.geoFlotea;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async blacklist({ commit }, inquiryUUID) {
        const body = JSON.stringify([
            {
                request: inquiryUUID,
                withDesc: 'true'
            }
        ]);

        const { data } = await axios.post(`${flotea}/blacklist`, body);

        return data;
    },

    async addToBlacklist({ commit }, params) {
        const { inquiryUUID, desc, phone } = params;

        const body = JSON.stringify([
            {
                request: inquiryUUID,
                desc
            },
            {
                phone,
                desc
            }
        ]);

        const { data } = await axios.put(`${flotea}/blacklist`, body);

        return data;
    },

    async tip({ commit }) {
        const { data } = await axios.post(`${flotea}/tip`);

        return data;
    },

    async autocomplete({ commit }, text) {
        const params = {
            text,
            layers: 'locality,postalcode',
            size: 10,
            'boundary.rect.min_lat': 35.46066,
            'boundary.rect.max_lat': 71.30079,
            'boundary.rect.min_lon': -11.6015,
            'boundary.rect.max_lon': 39.72656
        };

        const { data } = await axios.get(`${geoFlotea}/autocomplete`, {
            params
        });

        return data;
    },

    async search({ commit }, text) {
        const params = {
            text,
            layers: 'locality,postalcode',
            size: 5,
            'boundary.rect.min_lat': 35.46066,
            'boundary.rect.max_lat': 71.30079,
            'boundary.rect.min_lon': -11.6015,
            'boundary.rect.max_lon': 39.72656
        };

        const { data } = await axios.get(`${geoFlotea}/search`, {
            params
        });

        return data;
    },

    async postalCode({ commit }, params) {
        const { id, cnt, lat, lng } = params;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('cnt', cnt);
        formData.append('lat', lat);
        formData.append('lng', lng);

        try {
            const { data } = await axios.post(`get-postal-flotea`, formData);

            return data;
        } catch (error) {}
    },

    async sendPassenger({ commit }, params) {
        const body = JSON.stringify(params);

        const { data } = await axios.post(`${flotea}/send-passenger`, body);

        return data;
    },

    async searchCarriers({ commit }, params) {
        const { data } = await axios.get(`https://api.flotea.pl/search-carriers`, {
            params
        });

        return data;
    }
};
