<template slot="md-tab" slot-scope="{ tab }">
    <div class="">
        <Header v-if="loggedIn" name="headerCompontent" />
        <div v-else id="md-toolbar">
            <md-toolbar class="md-small md-primary">
                <div class="md-toolbar-row container">
                    <div class="md-toolbar-section-end">
                        <img
                            src="@assets/images/logo.svg"
                            alt="logo"
                            title="logo"
                            width="112"
                            height="23"
                        />
                    </div>
                </div>
            </md-toolbar>
        </div>
        <div id="notfound">
            <slot />
        </div>
    </div>
</template>

<script>
import Header from '../components/layout/Header';
import { mapGetters } from 'vuex';
export default {
    components: {
        Header
    },

    data() {
        return {};
    },

    computed: {
        ...mapGetters({
            loggedIn: 'auth/loggedIn'
        })
    }
};
</script>

<style scoped>
.md-toolbar-section-end {
    justify-content: center;
}
</style>
