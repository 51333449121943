import axios from 'axios';
import config from '@config';
const flotea = config.flotea;

export const state = {
    routeDetails: {},
    carrierDetails: {},
    views: 0,
    deposit: {},
    carrierDeposits: [],
    depositTotalCount: 0,
    depositPoints: 0,
    blackList: {},
    partner_price: null
};

const deposit = state => {
    return {
        deposit: state.carrierDeposits,
        points: state.depositPoints
    };
};

export const getters = {
    routeDetails: state => state.routeDetails,
    carrierDetails: state => state.carrierDetails,
    views: state => state.views,
    deposit: state => state.deposit,
    blackList: state => state.blackList,
    carrierDeposits: state => deposit(state),
    partnerPrice: state => state.partner_price
};

export const mutations = {
    ADD_ROUTE_DETAILS(state, response) {
        state.routeDetails = response.request.shift();
        state.partner_price = response.partner_price;
    },

    UPDATE_ROUTE_DETAILS(state, data) {
        state.routeDetails = { ...state.routeDetails, ...data };
    },

    ADD_PHONE_AND_MORE_DETAILS(state, data) {
        state.routeDetails = data;
    },

    ADD_CARRIER_DETAILS(state, data) {
        state.carrierDetails = data;
    },

    ADD_VIEWS(state, data) {
        state.views = data;
    },

    ADD_DEPOSIT(state, data) {
        state.deposit = data;
    },

    CLEAR_DEPOSIT(state) {
        state.carrierDeposits = [];
        state.depositTotalCount = 0;
    },

    ADD_CARRIER_DEPOSITS(state, data) {
        state.depositPoints = data.points;
        state.carrierDeposits.push(...data.deposit);
        state.depositTotalCount = data.total_count;
    },

    BLACK_LIST(state, data) {
        state.blackList = data;
    }
};

export const actions = {
    async getRoute({ commit }, inquiryUUID) {
        const { data } = await axios.get(`get-route/${inquiryUUID}`);

        commit('ADD_ROUTE_DETAILS', data);

        return data;
    },

    async getRoutePartner({ commit }, inquiryUUID) {
        const { data } = await axios.get(`get-route/${inquiryUUID}`);

        return data;
    },

    async getPhone({ commit }, inquiryUUID) {
        const { data } = await axios.get(`get-request-phone/${inquiryUUID}`);

        commit('ADD_PHONE_AND_MORE_DETAILS', data);

        return data;
    },

    async getCalled({ commit }, params) {
        const { carrierUUID, inquiryUUID } = params;

        const { data } = await axios.get(
            `get-called/${carrierUUID}/${inquiryUUID}`
        );

        if(!data){
            return {
                take: 0,
                call: 0
            }
        }

        return data;
    },

    async getAnyCall({ commit }, inquiryUUID) {
        const { data } = await axios.get(`any-call/${inquiryUUID}`);

        return data;
    },

    async getCarrier({ commit }, carrierUUID) {
        const { data } = await axios.get(`get-carrier/${carrierUUID}`);

        commit('ADD_CARRIER_DETAILS', data);

        return data;
    },

    async returnCarrier({ commit }, carrierUUID) {
        const { data } = await axios.get(`get-carrier/${carrierUUID}`);

        return data;
    },

    async getViews({ commit }, params) {
        const { carrierUUID, inquiryUUID } = params;

        const { data } = await axios.get(
            `get-viewed/${carrierUUID}/${inquiryUUID}`
        );

        commit('ADD_VIEWS', data);

        return data;
    },

    async getDepositApp({ commit }, params) {
        const { carrierUUID, inquiryUUID } = params;

        const { data } = await axios.get(
            `get-deposit-app/${carrierUUID}/${inquiryUUID}`
        );

        commit('ADD_DEPOSIT', data);

        return data;
    },

    async getAnyDepo({ commit }, inquiryUUID) {
        const { data } = await axios.get(`any-depo/${inquiryUUID}`);

        return data;
    },

    async getDeposit({ commit }, param) {
        const { page, perPage } = param.params;
        const params = {
            expand: 'request',
            page,
            'per-page': perPage
        };

        if (page === 1) {
            commit('CLEAR_DEPOSIT');
        }

        const { data } = await axios.get(`get-deposit-pwa`, { params });

        commit('ADD_CARRIER_DEPOSITS', data);

        return data;
    },

    async sendDeposit({ commit }, params) {
        const { data } = await axios.post(`send-deposit`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        return data;
    },

    async getBlacklist({ commit }, inquiryUUID) {
        const body = JSON.stringify([
            {
                request: inquiryUUID,
                withDesc: true
            }
        ]);

        const { data } = await axios.post(`${flotea}/blacklist`, body);

        if (data) {
            commit('BLACK_LIST', data.shift());
        }

        return data;
    },

    async setRouteReport({ commit }, params) {
        const { type, inquiryUUID } = params;

        const { data } = await axios.get(
            `/set-route-report/${type}/${inquiryUUID}`
        );

        return data;
    }
};
