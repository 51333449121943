import moment from 'moment';

export const timeAgo = (dataTime, locale) => {
    return moment(dataTime)
        .utc()
        .add(1, 'hours')
        .locale(locale)
        .fromNow();
};

export const formatDate = (dataTime, locale = '', format = 'DD MMM YYYY') => {
    return moment(dataTime)
        .utc()
        .add(2, 'hours')
        .locale(locale)
        .format(format);
};

export const isExpired = created_at => {
    const status = moment(created_at)
        .utc()
        .add(24, 'hours');
    const now = moment(
        moment()
            .utc()
            .format('YYYY-MM-DD HH:mm:ss.SSSS')
    );

    return now.isAfter(status);
};
