import Vue from 'vue';
import App from './app';
import router from './router';

import store from './state/store';
import Vuelidate from 'vuelidate';
import VueOffline from 'vue-offline';
import VueToast from 'vue-toast-notification';
import VueBridgeWebview from 'vue-bridge-webview';
import VueMaterialSlider from 'vue-material-slider';
import {
    MdTooltip,
    MdButton,
    MdContent,
    MdTabs,
    MdAvatar,
    MdCard,
    MdLayout,
    MdSnackbar,
    MdProgress,
    MdDialog,
    MdToolbar,
    MdMenu,
    MdList,
    MdSwitch,
    MdDivider,
    MdRadio,
    MdSpeedDial,
    MdTable
} from 'vue-material/dist/components';
import InfiniteLoading from 'vue-infinite-loading';

import '@assets/styles/main.scss';

import '../../plugins/axios.config';
import { i18n } from '../../plugins/i18n';
import globalFunctions from '../../plugins/global-functions';
import '../../registerServiceWorker';

Vue.config.productionTip = process.env.NODE_ENV === 'production';

Vue.use(VueMaterialSlider);
Vue.use(Vuelidate);
Vue.use(VueToast);
Vue.use(VueOffline);
Vue.use(MdButton);
Vue.use(MdContent);
Vue.use(MdTabs);
Vue.use(MdAvatar);
Vue.use(MdCard);
Vue.use(MdLayout);
Vue.use(MdSnackbar);
Vue.use(MdProgress);
Vue.use(MdDialog);
Vue.use(MdTooltip);
Vue.use(MdToolbar);
Vue.use(MdMenu);
Vue.use(MdList);
Vue.use(MdSwitch);
Vue.use(MdDivider);
Vue.use(MdRadio);
Vue.use(MdSpeedDial);
Vue.use(MdTable);
Vue.use(InfiniteLoading);
Vue.use(globalFunctions);
Vue.use(VueBridgeWebview);

VueBridgeWebview.config(0, true);

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app');

if (process.env.NODE_ENV === 'production') {
    window.OneSignal = window.OneSignal || [];
    window.OneSignal.push(() => {
        window.OneSignal.init({
            appId: process.env.VUE_APP_ONESIGNAL,
            safari_web_id: process.env.VUE_APP_ONESIGNAL_SAFARI_ID,
            notifyButton: {
                enable: true
            }
        });
    });
}
