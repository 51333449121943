<template>
    <md-dialog
        :md-fullscreen="false"
        :md-active="show"
        :md-click-outside-to-close="false"
    >
        <md-dialog-title>
            {{ $t('modals.addPoints') }}
            <button
                type="button"
                class="md-button close-modal md-theme-default"
            >
                <div
                    class="md-ripple closeX-default"
                    @click="$emit('closeModal')"
                >
                    <div class="md-button-content">
                        x
                    </div>
                </div>
            </button>
        </md-dialog-title>
        <div class="payment-radio">
            <div class="md-radio">
                <md-radio v-model="radio" :value="packageA"
                    ><div>
                        <strong>
                            2 500 {{ $t('header.shortcutPoints') }}
                            <span class="vip">
                                VIP
                            </span>
                            +
                            <span class="gold">
                                {{ $t('modals.golden') }}
                            </span>
                            {{ $t('modals.medal') }}
                            <br
                        /></strong>

                        <span class="small">
                            307 {{ $t('global.currency') }},
                            {{ $t('modals.inquiry') }} 12
                            {{ $t('modals.minsEarlier') }}
                        </span>
                        <span class="only-for-iphone">
                            307 {{ $t('global.currency') }}
                        </span>
                    </div></md-radio
                >
            </div>
            <div class="md-radio">
                <md-radio v-model="radio" :value="packageB">
                    <div>
                        <strong>
                            2 000 {{ $t('header.shortcutPoints') }}
                            <span class="vip">
                                VIP
                            </span>
                            +
                            <span class="silver">
                                {{ $t('modals.silver') }}
                            </span>
                            {{ $t('modals.medal') }}
                            <br
                        /></strong>

                        <span class="small">
                            246 {{ $t('global.currency') }},
                            {{ $t('modals.inquiry') }} 9
                            {{ $t('modals.minsEarlier') }}
                        </span>
                        <span class="only-for-iphone">
                            246 {{ $t('global.currency') }}
                        </span>
                    </div></md-radio
                >
            </div>
            <div class="md-radio">
                <md-radio v-model="radio" :value="packageC">
                    <div>
                        <strong>
                            1 500 {{ $t('header.shortcutPoints') }}
                            <span class="vip">
                                VIP
                            </span>
                            +
                            <span class="bronze">
                                {{ $t('modals.brown') }}
                            </span>
                            {{ $t('modals.medal') }}
                            <br
                        /></strong>

                        <span class="small">
                            185 {{ $t('global.currency') }},
                            {{ $t('modals.inquiry') }} 6
                            {{ $t('modals.minsEarlier') }}
                        </span>
                        <span class="only-for-iphone">
                            185 {{ $t('global.currency') }}
                        </span>
                    </div></md-radio
                >
            </div>
            <div class="md-radio">
                <md-radio v-model="radio" :value="packageD"
                    ><div>
                        <strong>
                            1 000 {{ $t('header.shortcutPoints') }}
                            <span class="vip">
                                VIP
                            </span></strong
                        >
                        <br />
                        <span class="small">
                            123 {{ $t('global.currency') }},
                            {{ $t('modals.inquiry') }} 3
                            {{ $t('modals.minsEarlier') }}
                        </span>
                        <span class="only-for-iphone">
                            123 {{ $t('global.currency') }}
                        </span>
                    </div></md-radio
                >
            </div>
        </div>

        <md-dialog-actions>
            <div class="pull-left mt5" style="left: 0; position: absolute;">
                <img src="@assets/images/payu-logo.png" alt="payu" /> &nbsp;
                <img src="@assets/images/visa.png" alt="visa" /> &nbsp;
                <img src="@assets/images/mastercard.png" alt="mastercard" />
            </div>

            <md-button
                v-if="radio"
                class="md-dense md-raised md-primary pull-right mt20"
                @click="buyCredit"
            >
                {{ waitingBuy ? '' : $t('modals.buyPoints') }}
                <img
                    v-if="waitingBuy"
                    src="@assets/images/loader.gif"
                    alt="loader"
                    title="loader"
                />
            </md-button>
            <md-button
                v-else
                class="md-dense md-raised md-primary pull-right mt20"
                disabled
            >
                {{ $t('modals.buyPoints') }}
            </md-button>
        </md-dialog-actions>
    </md-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            radio: false,
            packageA: { points: 2500, price: 307 },
            packageB: { points: 2000, price: 246 },
            packageC: { points: 1500, price: 185 },
            packageD: { points: 1000, price: 123 },
            waitingBuy: false
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser'
        })
    },

    methods: {
        ...mapActions({
            buyPoints: 'payments/buyPoints'
        }),

        safari() {
            return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
        },

        iphone() {
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                return true;
            } else {
                return false;
            }
        },

        async buyCredit() {
            if (this.radio.points > 0) {
                this.waitingBuy = true;

                const params = {
                    uuid: this.currentUser.carrierUUID,
                    url: window.location.href,
                    amount: this.radio.points
                };

                try {
                    const response = await this.buyPoints(params);
                    const url = response.data.toString();

                    setTimeout(() => {
                        if (this.iphone()) {
                            window.open(url, '_self');
                        } else if (this.safari()) {
                            window.open(url, '_self');
                        } else {
                            window.open(url);
                        }
                    }, 100);

                    this.waitingBuy = false;
                } catch (error) {
                    this.$toast.error(this.$t('global.somethingWrong'));
                }
            }
        }
    }
};
</script>
