import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async buyPoints({ commit }, params) {
        return await axios.post(`/buy-credit`, params);
    },

    async buyMedal({ commit }, params) {
        return await axios.post('/buy-medal', params);
    },

    async payOutDeposit({ commit }, params) {
        const { carrierUUID, amount, advancePayment } = params;

        const formData = new FormData();
        formData.append('uuid', carrierUUID);
        formData.append('amount', amount);
        formData.append('advancePayment', advancePayment);

        return await axios.post('/request-deposit', formData);
    }
};
